import { Movie } from '@usheru-hff/shared/domain';
import { inject, Injectable, PLATFORM_ID, signal } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { isReadable, TinyColor } from '@ctrl/tinycolor';
import { HeaderService } from '@usheru-hff/hff-common/ui-header';
import { TrackingService } from '@usheru-hff/shared/utils-tracking';
import { MovieService } from '@usheru-hff/shared/utils-movie';
import { lastValueFrom } from 'rxjs';
import { SharedFeatureSidemenuComponent } from '@usheru-hff/shared/feature-sidemenu';

@Injectable()
export class BrandMovieService {
  private readonly PLATFORM_ID = inject(PLATFORM_ID);
  private readonly isPlatformBrowser = isPlatformBrowser(this.PLATFORM_ID);
  private readonly route = inject(ActivatedRoute);
  private readonly movieService = inject(MovieService);
  private readonly headerService = inject(HeaderService);
  private readonly trackingService = inject(TrackingService);

  readonly movie = signal<Movie | undefined>(undefined);
  activeMovieRequest?: string;

  readonly desktopBreakpoint = 1200;

  sidemenuRef?: SharedFeatureSidemenuComponent;
  injectStylesEl?: HTMLStyleElement;
  footerRestoreRef?: {
    footer: HTMLElement;
    parent: HTMLElement;
    sibling: HTMLElement;
    insertPostion: 'after' | 'before';
  };

  constructor() {}

  setMovie(movie: Movie): void {
    this.movie.set(movie);
    // Maybe this is not the full movie object, so we fetch the full movie data
    lastValueFrom(this.movieService.getMovie(movie.friendlyUrl)).then(movie => {
      // Prevents this async action to override the current movie if is a different one
      if (this.movie()?.friendlyUrl !== movie.friendlyUrl) return;
      // Prevents this async action to override the current movie if is the same one
      if (JSON.stringify(this.movie()) === JSON.stringify(movie)) return;
      this.movie.set(movie);
    });
  }

  isMovieAwareness(movie: Movie): boolean {
    const today = new Date().getTime();
    if (movie.chSettings.movieExperienceAutomatedSwitch && movie.locCinemaEnd) {
      return movie.locCinemaEnd > today;
    } else {
      return true;
    }
  }

  /**
   * Generate the colors variables and set some global styles
   * @returns a string to be used in the HostBinding style
   */
  setColorStyles(brandColor: string, backgroundColor: string, borderRadius: string): string {
    const getColorStyling = (args: ColorSetInput): ColorSet => {
      const { color, textWhite, textBlack } = args;
      const redeableColor = isReadable(color, textBlack) ? textBlack : textWhite;
      const hoverColor = color.isLight() ? color.darken(10) : color.lighten(10);

      const white = new TinyColor('#ffffff').darken(5);
      const black = new TinyColor('#000000').lighten(5);
      let redeableColorContrast, redeableColorContrastInverse;
      if (isReadable(redeableColor, '#000000')) {
        redeableColorContrast = white;
        redeableColorContrastInverse = black;
      } else {
        redeableColorContrast = black;
        redeableColorContrastInverse = white;
      }

      return {
        color,
        redeableColor,
        redeableColorContrast,
        redeableColorContrastInverse,
        hoverColor,
        textWhite,
        textBlack
      };
    };
    const defaultWhite = new TinyColor('white');
    const defaultBlack = new TinyColor('black');

    const args = {
      color1: new TinyColor(brandColor),
      color2: new TinyColor(backgroundColor),
      color1TextWhite: defaultWhite,
      color1TextBlack: defaultBlack,
      color2TextWhite: defaultWhite,
      color2TextBlack: defaultBlack
    };

    const {
      color1,
      color2,
      color1TextWhite = defaultWhite,
      color1TextBlack = defaultBlack,
      color2TextWhite = defaultWhite,
      color2TextBlack = defaultBlack
    } = args;

    const color1Set = getColorStyling({ color: color1, textWhite: color1TextWhite, textBlack: color1TextBlack });
    const color2Set = getColorStyling({ color: color2, textWhite: color2TextWhite, textBlack: color2TextBlack });

    const variablesToSet = `
      --brand-color: ${color1Set.color.toHexString()};
      --brand-color-contrast: ${color1Set.redeableColor.toHexString()};
      --brand-color-hover: ${color1Set.hoverColor.toHexString()};
      --brand-color-text-white: ${color1Set.textWhite.toHexString()};
      --brand-color-text-black: ${color1Set.textBlack.toHexString()};
      --background-color: ${color2Set.color.toHexString()};
      --background-color-contrast: ${color2Set.redeableColor.toHexString()};
      --background-color-hover: ${color2Set.hoverColor.toHexString()};
      --background-color-text-white: ${color2Set.textWhite.toHexString()};
      --background-color-text-black: ${color2Set.textBlack.toHexString()};
      --brand-color-contrast-support: ${color1Set.redeableColorContrast?.toHexString() || ''};
      --brand-color-contrast-support-contrast: ${color1Set.redeableColorContrastInverse?.toHexString() || ''};
      --background-color-contrast-support: ${color2Set.redeableColorContrast?.toHexString() || ''};
      --background-color-contrast-support-contrast: ${color2Set.redeableColorContrastInverse?.toHexString() || ''};
      --border-radius: ${borderRadius};
    `;

    this.headerService.setHeaderBingingStyle('display: none');

    this.injectRootStyles(color2Set.color.toHexString());

    this.injectFooterColorStyles(
      color1Set.color.toHexString(),
      color1Set.redeableColor.toHexString(),
      color1Set.hoverColor.toHexString(),
      color2Set.color.toHexString(),
      color2Set.redeableColor.toHexString(),
      color2Set.hoverColor.toHexString(),
      color2Set.redeableColor.toHexString(),
      borderRadius
    );
    this.injectGoToTopStyles(
      color1Set.color.toHexString(),
      color1Set.redeableColor.toHexString(),
      color2Set.color.toHexString()
    );

    return variablesToSet;
  }

  injectStyles(fontFamily: string, fontFamilyBold: string) {
    if (!this.isPlatformBrowser) return;

    if (this.injectStylesEl) {
      this.removeStyles();
    }

    const style = document.createElement('style');
    style.innerHTML = `
      body {
        --headerHeight: 0;
        --headerHeightMobile: 0;
      }
      body .page-wrapper {
        overflow: visible;
      }
      ush-header {
        display: none;
        }
      ush-footer {
        position: relative;
        background-color: white;
      }

      #footer-subscribe-form {
        margin: 0 !important;
        padding: 0 !important;
        background-color: var(--subscribeBackground);
      }

      #footer-subscribe-form .subscribe-form {
        width: 90vw;
        max-width: 1700px;
        margin: 0 auto;
        padding-right: 0px;
        padding-left: 0px;
      }

    `;
    style.id = 'movie-awarness-page-styles';
    document.head.appendChild(style);
    this.injectStylesEl = style;

    this.setFontsStyles(fontFamily, fontFamilyBold);
  }

  private injectRootStyles(backgroundColor: string) {
    if (!this.isPlatformBrowser) return;
    if (!this.injectStylesEl) return;
    this.injectStylesEl.innerHTML += `
     :root {
       background-color: ${backgroundColor};
      `;
  }

  private injectFooterColorStyles(
    brandColor: string,
    brandColorContrast: string,
    brandColorHover: string,
    backgroundColor: string,
    backgroundColorContrast: string,
    backgroundColorHover: string,
    textColor: string,
    borderRadious: string
  ) {
    if (!this.isPlatformBrowser) return;
    if (!this.injectStylesEl) return;
    this.injectStylesEl.innerHTML += `
      ush-footer {
        --brandColor: ${brandColor};
        --brandColorContrast: ${brandColorContrast};
        --brandColorHover: ${brandColorHover};
        --backgroundColor: ${backgroundColor};
        --backgroundColorContrast: ${backgroundColorContrast};
        --backgroundColorHover: ${backgroundColorHover};
        --textColor: ${textColor};
        --borderRadius: ${borderRadious};

        --footerBackground: var(--backgroundColor);
        --subscribeBackground: var(--backgroundColor);

        --subscribeTitleColor: var(--textColor);
        --subscribeDescColor: var(--textColor);


        --footerLinksColor: var(--textColor);

        --dropdownTriggerBackgroundColor: var(--brandColor);
        --dropdownTriggerColor: var(--brandColorContrast);
      }

      ush-footer  .social-link {
        --socialLinksColor: var(--brandColor);
      }

      ush-footer .button-secondary {
        background-color: var(--brandColor);
        color: var(--brandColorContrast);
        border: 2px solid var(--brandColor);
      }

      ush-footer .button-secondary:is(:hover, :focus) {
        background-color: var(--brandColorHover);
        color: var(--brandColorContrast);
        border: 2px solid var(--brandColor);
      }
    `;
  }

  injectCustomFooterStyle(styles: string) {
    if (!this.isPlatformBrowser) return;
    if (!this.injectStylesEl) return;
    this.injectStylesEl.innerHTML += styles;
  }

  private injectGoToTopStyles(brandColor: string, brandColorContrast: string, backgroundColor: string) {
    if (!this.isPlatformBrowser) return;
    if (!this.injectStylesEl) return;
    this.injectStylesEl.innerHTML += `
      #goToTop {
        --brandColor: ${brandColor};
        --brandColorContrast: ${brandColorContrast};
        --backgroundColor: ${backgroundColor};

        color: var(--brandColorContrast) !important;
      }
    `;
  }

  removeStyles() {
    this.headerService.setHeaderBingingStyle('');
    if (this.sidemenuRef) {
      this.sidemenuRef = undefined;
    }
    if (this.injectStylesEl) {
      this.injectStylesEl.remove();
      this.injectStylesEl = undefined;
    }
  }

  setFontsStyles(fontFamily: string, fontFamilyBold: string) {
    if (!this.injectStylesEl) return;

    const regularFount = `https://cdnstatic.usheru.com/fonts/${fontFamily}`;
    const boldFont = `https://cdnstatic.usheru.com/fonts/${fontFamilyBold}`;

    const getFontFormat = (url: string) => {
      const extension = url.split('.').pop();
      switch (extension) {
        case 'woff':
          return 'woff';
        case 'woff2':
          return 'woff2';
        case 'ttf':
          return 'truetype';
        case 'otf':
          return 'opentype';
        case 'eot':
          return 'embedded-opentype';
        default:
          return '';
      }
    };

    this.injectStylesEl.innerHTML += `
      @font-face {
        font-family: 'movie-font';
        src: url('${regularFount}') format('${getFontFormat(regularFount)}');
        font-weight: 500;
        font-style: normal;
      }
      @font-face {
        font-family: 'movie-font';
        src: url('${boldFont}') format('${getFontFormat(boldFont)}');
        font-weight: 700;
        font-style: bold;
      }
    `;
  }

  moveFooter(refElement: HTMLElement) {
    if (!this.isPlatformBrowser) return;

    const footer = document.querySelector('ush-footer') as HTMLElement;
    const parent = footer?.parentElement as HTMLElement;
    let sibling, insertPostion: 'after' | 'before';
    const nextSibling = footer?.nextElementSibling as HTMLElement;
    if (nextSibling) {
      sibling = nextSibling;
      insertPostion = 'before';
    }
    const previousSibling = footer?.previousElementSibling as HTMLElement;
    if (previousSibling) {
      sibling = previousSibling;
      insertPostion = 'after';
    }

    if (!nextSibling && !previousSibling) {
      throw new Error('Footer has no sibling to be moved');
    }

    this.footerRestoreRef = { footer, parent, sibling: sibling!, insertPostion: insertPostion! };
    refElement.appendChild(footer);
  }

  restoreFooter() {
    if (!this.isPlatformBrowser) return;
    if (!this.footerRestoreRef) return;
    const { footer, parent, sibling, insertPostion } = this.footerRestoreRef;
    if (!footer || !parent || !sibling || !insertPostion) return;
    if (this.footerRestoreRef.insertPostion === 'after') {
      this.footerRestoreRef?.parent.insertBefore(
        this.footerRestoreRef.footer,
        this.footerRestoreRef.sibling.nextSibling
      );
    } else {
      this.footerRestoreRef?.parent.insertBefore(this.footerRestoreRef.footer, this.footerRestoreRef.sibling);
    }
  }

  checkAnchor() {
    const fragment = this.route.snapshot.fragment;
    if (fragment) {
      const section = document.getElementById(fragment) as HTMLElement;
      if (!section) return;
      this.goToSection(section, 'instant');
    }
  }

  goToSection(
    section: HTMLElement,
    behavior: ScrollBehavior = 'smooth',
    tracking?: {
      section: string;
      movie: Movie;
    }
  ) {
    if (!this.isPlatformBrowser) return;

    if (tracking) {
      const { section, movie } = tracking;
      this.trackingService.trackEvent({
        action: 'MovieMenu',
        properties: {
          category: 'MovieDetail',
          label: section,
          movie: movie.title || movie.friendlyUrl,
          idMovie: movie.id,
          imdb: movie?.imdb,
          tagsMovie: movie.tags?.map(t => t.friendlyUrl) || [],
          genresMovie: movie.genres?.map(g => g.slug) || []
        }
      });
    }

    if (section) section.scrollIntoView({ behavior });
    if (this.sidemenuRef) this.sidemenuRef.close();
  }

  hexToRgb(hex: string): number[] | null {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
  }

  get isDesktop() {
    return window.innerWidth >= this.desktopBreakpoint;
  }
}

interface PageColorsSetInput {
  color1: TinyColor;
  color2: TinyColor;
  color1TextWhite?: TinyColor;
  color1TextBlack?: TinyColor;
  color2TextWhite?: TinyColor;
  color2TextBlack?: TinyColor;
  borderRadius: string;
}

interface ColorSetInput {
  color: TinyColor;
  textWhite: TinyColor;
  textBlack: TinyColor;
}

interface ColorSet {
  color: TinyColor;
  redeableColor: TinyColor;
  hoverColor: TinyColor;
  textWhite: TinyColor;
  textBlack: TinyColor;
  /**
   * This color must be black or white. Used as support color for some contrast cases.
   */
  redeableColorContrast?: TinyColor;
  /**
   * This color must be black or white. Used as support color for some contrast cases.
   */
  redeableColorContrastInverse?: TinyColor;
}
